<template>
  <el-dialog title="配置管理" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" custom-class="dialog-full-hasfoot" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <!-- 添加部分（商户&税源地&通道） -->
      <div v-show="add == 1">
        <FormView ref="vFormNew" :labels="formNewLabels" :formConfig="formConfig" @onInputChange="onInputChangeNew">
          <!-- 添加时的商户提醒 -->
          <template v-slot:merchantSuffix>
            <el-col>
              <el-alert
                title="变更商户后，已选的税源地和通道将重置，请谨慎操作"
                type="warning"
                show-icon
                :closable="false"
                style="margin-bottom: 18px"
              >
              </el-alert>
            </el-col>
          </template>
        </FormView>
      </div>
      <!-- 编辑部分 -->
      <FormView v-show="add != 1" ref="vForm" :labels="formLabels" :formConfig="formConfig" @onInputChange="onInputChange">
        <!-- 阶梯费率 -->
        <template v-slot:saveMerchantTariffing>
          <el-col>
            <el-form-item>
              <el-button plain type="primary" icon="el-icon-plus" @click="addTariffing()" style="margin-bottom: 12px">添加</el-button>
              <!-- 阶梯税率表格 -->
              <el-table ref="merchantTariffingListTable" :data="merchantTariffingList" border>
                <el-table-column label="发放金额">
                  <template v-slot="{ row, $index }">
                    <el-input-number :controls="false" :precision="0" disabled :value="issueAmountBefore($index)" />
                    -
                    <el-input-number
                      :controls="false"
                      :min="issueAmountBefore($index)"
                      :max="1000000000000"
                      :precision="0"
                      v-model="row.issueAmount"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="服务费率">
                  <template v-slot="{ row }">
                    <el-input-number :controls="false" :min="0" :precision="2" :max="100" v-model="row.serviceTariffing" />
                    %
                  </template>
                </el-table-column>
                <el-table-column label="成本费率">
                  <template v-slot="{ row }">
                    <el-input-number :controls="false" :min="0" :precision="2" :max="100" v-model="row.costServiceTariffing" />
                    %
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                  <template v-slot="{ row, $index }">
                    <el-button type="text" class="el-button-error" @click="deleteTariffing(row, $index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </template>
        <!-- 盖章关键字 -->
        <template v-slot:keywordDtos="{ form, idx }">
          <el-col>
            <el-form-item label="盖章关键字" class="is-required">
              <el-table :data="merchantKeywordList">
                <el-table-column prop="keyword" label="关键字" width="120"> </el-table-column>
                <el-table-column prop="signatoryType" label="签约方类型" width="100">
                  <template slot-scope="{ row }">{{ formatStatus(stampOptions, row.signatoryType) }}</template>
                </el-table-column>
                <el-table-column prop="signatoryName" label="签约方" width="300">
                  <template slot-scope="{ row }">
                    <!-- 商户&创客 -->
                    <el-input
                      v-if="row.signatoryType == 1 || row.signatoryType == 2"
                      disabled
                      :placeholder="row.signatoryType == 1 ? '商户主体' : '创客'"
                    ></el-input>
                    <el-select v-else v-model="row.signatoryId" filterable style="width: 100%" @change="onSignatoryChange($event, row)">
                      <el-option
                        style="width: 300px"
                        v-for="item in signatoryOptions"
                        :key="item.mainId"
                        :label="item.mainCode + ' ' + item.mainName"
                        :value="item.mainId"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="授权状态" width="80" prop="authorState"></el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </template>
        <!-- 税目 -->
        <template v-slot:saveMerchantTax="{ idx, form }">
          <el-col>
            <el-form-item label="可开票税目" class="is-required" :error="taxError">
              <el-popover placement="bottom-start" width="600" trigger="manual" v-model="showTaxPopover">
                <div slot="reference">
                  <el-button type="primary" @click="openTaxPopover">添加</el-button>
                </div>
                <div style="max-height: 500px; overflow: auto" class="tax-pop">
                  <div class="text">税目列表</div>
                  <el-tree
                    :data="form.isThirdInvoice ? taxHmItemsLevelList : taxItemsLevelList"
                    show-checkbox
                    default-expand-all
                    :default-checked-keys="checkTaxItemsId"
                    node-key="value"
                    ref="taxAllTree"
                  >
                    <span class="custom-tree-node" slot-scope="{ data }">
                      <span class="label">{{ data.title || '' }}</span
                      >&nbsp;
                      <span class="label-num">{{ data.taxCode || '' }}</span>
                    </span>
                  </el-tree>
                </div>
                <div style="text-align: right; margin: 0">
                  <el-button type="text" @click="showTaxPopover = false">取消</el-button>
                  <el-button type="primary" @click="getTaxTree">确定</el-button>
                </div>
              </el-popover>
              <el-tree
                style="width: 400px"
                v-if="merchantTaxList.length > 0"
                :data="merchantTaxList"
                default-expand-all
                node-key="value"
                ref="taxTree"
              >
                <span class="custom-tree-node" slot-scope="{ data }">
                  <span class="label">{{ data.title || '' }}</span
                  >&nbsp;
                  <span class="label-num">{{ data.taxCode || '' }}</span>
                </span>
              </el-tree>
            </el-form-item>
          </el-col>
        </template>
        <!-- 金额范围 -->
        <template v-slot:isDeliverableAmountLimit="{ idx, form }">
          <el-col>
            <el-form-item
              label="金额范围"
              prop="isDeliverableAmountLimit"
              :rules="[
                {
                  required: true,
                  message: '请选择金额范围',
                  trigger: 'change'
                }
              ]"
            >
              <el-radio-group v-model="form.isDeliverableAmountLimit">
                <el-radio :label="0">不限制</el-radio>
                <el-radio :label="1">限制</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isDeliverableAmountLimit">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="deliverableMinAmount"
                  :rules="[
                    {
                      required: true,
                      message: '请填写最小金额',
                      trigger: 'blur'
                    }
                  ]"
                  ><el-input-number :controls="false" :min="0" :precision="2" v-model="form.deliverableMinAmount"
                /></el-form-item>
                <span> - </span>
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="deliverableMaxAmount"
                  :rules="[
                    {
                      required: true,
                      message: '请填写最大金额',
                      trigger: 'blur'
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (value < form.deliverableMinAmount) {
                          callback($error('需要大于最小金额'))
                        } else {
                          callback()
                        }
                      },
                      trigger: 'blur'
                    }
                  ]"
                  ><el-input-number :controls="false" :min="0" :precision="2" v-model="form.deliverableMaxAmount"
                /></el-form-item>
              </div>
            </el-form-item>
          </el-col>
        </template>
        <!-- 禁止发放时间段 -->
        <template v-slot:isTimeLimt="{ idx, form }">
          <el-col>
            <el-form-item label="时间范围" prop="isProhibitGrantTimeLimit">
              <el-radio-group v-model="form.isProhibitGrantTimeLimit">
                <el-radio :label="0">不限制</el-radio>
                <el-radio :label="1">限制</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isProhibitGrantTimeLimit">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="prohibitGrantTimeStart"
                  :rules="[
                    {
                      required: true,
                      message: '请选择开始时间',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <el-time-picker
                    v-model="form.prohibitGrantTimeStart"
                    placeholder="选择开始时间"
                    :editable="false"
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                  >
                  </el-time-picker
                ></el-form-item>
                <span> - </span>
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="prohibitGrantTimeEnd"
                  :rules="[
                    {
                      required: true,
                      message: '请选择结束时间',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <el-time-picker
                    v-model="form.prohibitGrantTimeEnd"
                    placeholder="选择结束时间"
                    :editable="false"
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                  >
                  </el-time-picker
                ></el-form-item>
              </div>
            </el-form-item>
          </el-col>
        </template>
        <!-- 电核金额范围 -->
        <!-- <template v-slot:isELeAmountLimit="{ idx, form }">
          <el-col>
            <el-form-item label="金额范围" prop="isELeAmountLimit" :rules="[{ required: true, message: '请选择金额范围', trigger: 'change' }]">
              <el-radio-group v-model="form.isELeAmountLimit">
                <el-radio :label="0">不限制</el-radio>
                <el-radio :label="1">限制</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isELeAmountLimit">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="eleMinAmount"
                  :rules="[{ required: true, message: '请填写最小金额', trigger: 'blur' }]"
                  ><el-input-number :controls="false" :min="0" :precision="2" v-model="form.eleMinAmount"
                /></el-form-item>
                <span> - </span>
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="eleMaxAmount"
                  :rules="[
                    { required: true, message: '请填写最大金额', trigger: 'blur' },
                    {
                      validator: (rule, value, callback) => {
                        if (value < form.eleMinAmount) {
                          callback($error('需要大于最小金额'))
                        } else {
                          callback()
                        }
                      },
                      trigger: 'blur'
                    }
                  ]"
                  ><el-input-number :controls="false" :min="0" :precision="2" v-model="form.eleMaxAmount"
                /></el-form-item>
              </div>
            </el-form-item>
          </el-col>
        </template> -->
        <!-- 发放年龄限制 -->
        <template v-slot:limitAge="{ idx, form }">
          <el-col>
            <el-form-item
              label="发放年龄限制"
              prop="limitAgeType"
              :rules="[
                {
                  required: true,
                  message: '请选择发放年龄限制方式',
                  trigger: 'change'
                }
              ]"
            >
              <el-radio-group v-model="form.limitAgeType">
                <el-radio :label="0">系统设置</el-radio>
                <el-radio :label="1">自定义设置</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.limitAgeType">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="limitAgeMin"
                  :rules="[
                    {
                      required: true,
                      message: '请填写最小年龄',
                      trigger: 'blur'
                    }
                  ]"
                  ><el-input-number :controls="false" :min="0" :max="120" :precision="0" v-model="form.limitAgeMin" placeholder="最小年龄"
                /></el-form-item>
                <span> - </span>
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="limitAgeMax"
                  :rules="[
                    {
                      required: true,
                      message: '请填写最大年龄',
                      trigger: 'blur'
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (value < form.eleMinAmount) {
                          callback($error('需要大于最小年龄'))
                        } else {
                          callback()
                        }
                      },
                      trigger: 'blur'
                    }
                  ]"
                  ><el-input-number
                    :controls="false"
                    :min="0"
                    :max="120"
                    :precision="0"
                    v-model="form.limitAgeMax"
                    placeholder="最大年龄"
                  />&nbsp;岁</el-form-item
                >
              </div>
            </el-form-item>
          </el-col>
        </template>
        <!-- 风控通知短信 -->
        <template v-slot:isRiskNotification="{ idx, form }">
          <el-col>
            <el-form-item label="风控通知短信" prop="isRiskNotification">
              <el-radio-group v-model="form.isRiskNotification">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isRiskNotification">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="riskSmsTemplate"
                  :rules="[
                    {
                      required: true,
                      message: '请选择短信模板',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <div style="display: flex; align-items: center">
                    <el-select v-model="form.riskSmsTemplate" filterable style="width: 100%" @change="getSmsContent($event)">
                      <el-option
                        style="width: 300px"
                        v-for="item in smsOptions"
                        :key="item.id"
                        :label="item.smsName + ' ' + item.smsCode"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-tooltip v-if="smsContent != ''" style="margin-left: 10px" effect="dark" :content="smsContent"
                      ><i class="el-icon-warning-outline"></i
                    ></el-tooltip>
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
          </el-col>
        </template>
        <!-- 退汇短信通知 -->
        <template v-slot:isReturnNotify="{ idx, form }">
          <el-col>
            <el-form-item label="退汇短信通知" prop="isReturnNotify">
              <el-radio-group v-model="form.isReturnNotify">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isReturnNotify">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="returnSmsTemplate"
                  :rules="[
                    {
                      required: true,
                      message: '请选择短信模板',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <div style="display: flex; align-items: center">
                    <el-select v-model="form.returnSmsTemplate" filterable style="width: 100%" @change="getReturnSmsContent($event)">
                      <el-option
                        style="width: 300px"
                        v-for="item in smsReturnOptions"
                        :key="item.id"
                        :label="item.smsName + ' ' + item.smsCode"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-tooltip v-if="smsReturnContent != ''" style="margin-left: 10px" effect="dark" :content="smsReturnContent"
                      ><i class="el-icon-warning-outline"></i
                    ></el-tooltip>
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
          </el-col>
        </template>
        <!-- 打款附言 -->
        <template v-slot:isTransferRemark="{ idx, form }">
          <el-col>
            <el-form-item label="打款附言" prop="isTransferRemark">
              <template slot="label">
                <el-tooltip effect="dark" content="使用支付宝账号收款才能展示附言"><i class="el-icon-warning-outline"></i></el-tooltip>
                打款附言：
              </template>
              <el-radio-group v-model="form.isTransferRemark">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isTransferRemark">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="transferRemark"
                  :rules="[
                    {
                      required: true,
                      message: '请填写打款附言',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <el-input v-model="form.transferRemark" placeholder="不得超过20字"> </el-input
                ></el-form-item>
              </div>
            </el-form-item>
          </el-col>
        </template>
        <template v-slot:isPunchRemark="{ idx, form }">
          <el-col>
            <el-form-item label="打款备注" prop="isPunchRemark">
              <template slot="label">
                <el-tooltip effect="dark" content="目前是争对使用招商银行打款的回单摘要配置"><i class="el-icon-warning-outline"></i></el-tooltip>
                打款备注：
              </template>
              <el-radio-group v-model="form.isPunchRemark">
                <el-radio :label="0">无</el-radio>
                <el-radio :label="1">自定义</el-radio>
                <el-radio :label="2">使用结算单备注</el-radio>
              </el-radio-group>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isPunchRemark == 1">
                <el-form-item
                  style="display: inline-block; margin-bottom: 0"
                  prop="punchRemark"
                  :rules="[
                    {
                      required: true,
                      message: '请填写打款备注',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <el-input v-model="form.punchRemark" maxlength="20" placeholder="不得超过20字"> </el-input
                ></el-form-item>
              </div>
              <div style="display: inline-block; margin-left: 30px" v-if="form.isPunchRemark == 2">
                <span>结算单备注超过20个字会被自动截断，建议告知客户</span>
              </div>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button v-if="add == 2" @click="prevStep">上一步</el-button>
      <el-button v-else @click="show = false">取 消</el-button>
      <el-button v-if="add == 1" type="primary" @click="nextStep">下一步</el-button>
      <el-button v-else type="primary" @click="submit" :loading="btnloadSubmit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { channelForm, channelSubmit } from './channel'
import { formatStatus, signType, kvOpenMode, kvContractMode, kvContractScheme10, kvContractScheme20, kvContractScheme30 } from '@/configs/formatters'
import { isEmpty } from '@/utils'
import { regular } from '@/configs/validate'

const formLabels = [
  {
    cmp: 'txt',
    label: '商户',
    prop: '_merchant'
    // show: 'form._isEdit'
  },
  {
    cmp: 'txt',
    label: '税源地',
    prop: '_supplier'
    // show: 'form._isEdit'
  },
  {
    cmp: 'txt',
    label: '渠道归属',
    prop: '_channel'
    // show: 'form._isEdit'
  },
  // 费率设置
  {
    sessionTitle: '费率设置',
    cmp: 'radio',
    label: '费率类型',
    prop: 'isPiecewise',
    options: [
      { key: 0, label: '固定费率' },
      { key: 1, label: '阶梯费率' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择费率类型', trigger: 'change' }]
    }
  },
  {
    cmp: 'number',
    label: '服务费率',
    prop: 'serviceTariffing',
    afterSuffix: '%',
    config: {
      controls: false,
      min: 0,
      precision: 2,
      max: 100
    },
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请填写服务费率',
          trigger: ['blur', 'change']
        }
      ]
    },
    show: '!form.isPiecewise'
  },
  {
    cmp: 'number',
    label: '渠道费率',
    prop: 'channelTariffing',
    afterSuffix: '%',
    config: {
      controls: false,
      min: 0,
      precision: 2,
      max: 100
    },
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请填写渠道费率',
          trigger: ['blur', 'change']
        }
      ]
    },
    show: '!form.isPiecewise && form.channelId && form.channelId!="00000000000000000000000000000000"'
  },
  {
    cmp: 'number',
    label: '成本费率',
    prop: 'costServiceTariffing',
    afterSuffix: '%',
    config: {
      controls: false,
      min: 0,
      precision: 2,
      max: 100
    },
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请填写成本费率',
          trigger: ['blur', 'change']
        }
      ]
    },
    show: '!form.isPiecewise'
  },
  {
    cmp: 'slot',
    name: 'saveMerchantTariffing',
    show: 'form.isPiecewise==1'
  },
  // 电签设置
  {
    sessionTitle: '电签设置',
    cmp: 'radio',
    label: '签约类型',
    prop: 'contractMode',
    options: kvContractMode,
    itemConfig: {
      rules: [{ required: true, message: '请选择签约类型', trigger: 'change' }]
    }
  },
  {
    cmp: 'radio',
    label: '签约方案',
    prop: 'contractScheme',
    options: kvContractScheme20,
    itemConfig: {
      rules: [{ required: true, message: '请选择签约方案', trigger: 'change' }]
    },
    show: 'form.contractMode==20'
  },
  {
    cmp: 'radio',
    label: '签约方案',
    prop: 'contractScheme',
    options: kvContractScheme30,
    itemConfig: {
      rules: [{ required: true, message: '请选择签约方案', trigger: 'change' }]
    },
    show: 'form.contractMode==30'
  },
  {
    cmp: 'radio',
    label: '签约方案',
    prop: 'contractScheme',
    options: kvContractScheme10,
    itemConfig: {
      rules: [{ required: true, message: '请选择签约方案', trigger: 'change' }]
    },
    show: 'form.contractMode==10'
  },
  {
    cmp: 'select',
    label: '电签协议模板',
    prop: 'peopleTemplateId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择电签协议模板', trigger: 'change' }]
    },
    config: {
      style: 'width: 350px'
    }
  },
  {
    cmp: 'slot',
    name: 'keywordDtos'
  },
  {
    cmp: 'number',
    label: '签约率',
    prop: 'signRate',
    afterSuffix: '%',
    config: {
      controls: false,
      min: 0,
      precision: 1,
      max: 100
    },
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请填写签约率',
          trigger: ['blur', 'change']
        }
      ]
    }
  },
  {
    cmp: 'radio',
    label: '签约校验',
    prop: 'isSigned',
    options: [
      { key: 1, label: '关闭' },
      {
        key: 0,
        label: '开启',
        tip: '开启后，结算单导入时，只能导入已签约的创客'
      }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择是否签约校验', trigger: 'change' }]
    }
  },
  // 税票设置
  {
    sessionTitle: '税票设置',
    cmp: 'radio',
    label: '第三方开票服务',
    prop: 'isThirdInvoice',
    options: [
      { key: 0, label: '关闭' },
      { key: 2, label: '开启，仅获取税目' },
      { key: 1, label: '开启，并使用第三方开票服务' }
    ],
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请选择是否第三方开票服务',
          trigger: 'change'
        }
      ]
    }
  },
  {
    cmp: 'input',
    label: 'appKey',
    prop: 'hmCode',
    itemConfig: {
      rules: [{ required: true, message: '请输入第三方appKey', trigger: 'blur' }]
    },
    show: '!!form.isThirdInvoice'
  },
  {
    cmp: 'input',
    label: 'secretKey',
    prop: 'hmSecretKey',
    itemConfig: {
      rules: [{ required: true, message: '请输入第三方secretKey', trigger: 'blur' }]
    },
    show: '!!form.isThirdInvoice'
  },
  {
    cmp: 'input',
    label: 'publicKey',
    prop: 'hmPublicKey',
    itemConfig: {
      rules: [{ required: true, message: '请输入第三方publicKey', trigger: 'blur' }]
    },
    show: '!!form.isThirdInvoice'
  },
  {
    cmp: 'input',
    label: 'mainstayId',
    prop: 'mainstayId',
    itemConfig: {
      rules: [{ required: true, message: '请输入第三方mainstayId', trigger: 'blur' }]
    },
    show: '!!form.isThirdInvoice'
  },
  {
    cmp: 'slot',
    name: 'saveMerchantTax'
  },
  {
    cmp: 'radio',
    label: '发票附件',
    prop: 'isUploadBillPic',
    options: [
      { key: 1, label: '必传' },
      { key: 0, label: '非必传' }
    ],
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请选择是否上传发票附件',
          trigger: 'change'
        }
      ]
    }
  },
  {
    cmp: 'radio',
    label: '开票类型',
    prop: 'settlementMakeInvoice',
    options: [
      { key: 0, label: '按结算单开票' },
      { key: 1, label: '按结算明细开票' },
      { key: 2, label: '按金额开票' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '选择结算开票', trigger: 'change' }]
    }
  },
  // { // 暂时挂起
  //   cmp: 'radio',
  //   label: '充值开票',
  //   prop: 'rechargeMakeInvoice',
  //   options: [
  //     { key: 0, label: '关闭' },
  //     { key: 1, label: '开启' }
  //   ],
  //   itemConfig: {
  //     rules: [{ required: true, message: '选择结算充值', trigger: 'change' }]
  //   },
  // },
  // 额度管控
  {
    sessionTitle: '额度管控',
    cmp: 'radio',
    label: '额度控制',
    prop: 'isControl',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '开启' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择是否额度控制', trigger: 'change' }]
    }
  },
  {
    cmp: 'txt',
    prop: '_controlTip',
    beforeSuffix: '以下两项，至少一项不为0，0表示不开启此项限制：',
    show: '!!form.isControl'
  },
  {
    cmp: 'number',
    prop: 'sigleLimit',
    beforeSuffix: '单笔发放金额不得超过',
    afterSuffix: '元',
    config: {
      controls: false,
      min: 0,
      precision: 2
    },
    show: '!!form.isControl'
  },
  {
    cmp: 'number',
    prop: 'monthLimit',
    beforeSuffix: '每人每月发放金额不得超过',
    afterSuffix: '元',
    config: {
      controls: false,
      min: 0,
      precision: 2
    },
    show: '!!form.isControl'
  },
  {
    cmp: 'number',
    prop: 'minAmount',
    label: '单笔最小金额',
    afterSuffix: '元',
    config: {
      controls: false,
      min: 0.01,
      precision: 2,
      style: {
        width: '300px'
      }
    },
    itemConfig: {
      rules: [{ required: true, message: '请输入单笔最小金额', trigger: 'blur' }]
    }
  },
  // 要素验证
  {
    sessionTitle: '要素验证',
    cmp: 'select',
    label: '创客信息校验',
    prop: 'verifyPeople',
    options: [
      { key: '0', label: '关闭' },
      { key: '1', label: '身份证二要素(姓名+身份证号码)' },
      { key: '2', label: '手机号三要素(姓名+身份证号码+手机号)' },
      { key: '3', label: '银行卡三要素(姓名+身份证号+银行卡号)' },
      { key: '4', label: '银行卡四要素（姓名+身份证号+银行卡号+预留手机号）' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择创客信息校验', trigger: 'change' }]
    },
    config: {
      multiple: true,
      style: 'width: 350px'
    }
  },
  {
    cmp: 'select',
    label: '结算单校验（支付宝）',
    prop: 'verifyAlipay',
    options: [
      { key: '0', label: '关闭' },
      { key: '1', label: '身份证二要素(姓名+身份证号码)' },
      { key: '2', label: '手机号三要素(姓名+身份证号码+手机号)' }
      // { key: '3', label: '支付宝校验（姓名+支付宝）' }
    ],
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请选择结算单校验（支付宝）',
          trigger: 'change'
        }
      ]
    },
    config: {
      multiple: true,
      style: 'width: 350px'
    }
  },
  {
    cmp: 'select',
    label: '结算单校验（银行卡）',
    prop: 'verifyBank',
    options: [
      { key: '0', label: '关闭' },
      { key: '1', label: '身份证二要素(姓名+身份证号码)' },
      { key: '2', label: '手机号三要素(姓名+身份证号码+手机号)' },
      { key: '3', label: '银行卡三要素(姓名+身份证号+银行卡号)' },
      { key: '4', label: '银行卡四要素（姓名+身份证号+银行卡号+预留手机号）' }
    ],
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请选择结算单校验（银行卡）',
          trigger: 'change'
        }
      ]
    },
    config: {
      multiple: true,
      style: 'width: 350px'
    }
  },
  {
    cmp: 'select',
    label: '结算单校验（微信）',
    prop: 'verifyWechat',
    options: [
      { key: '0', label: '关闭' },
      { key: '1', label: '身份证二要素(姓名+身份证号码)' },
      { key: '2', label: '手机号三要素(姓名+身份证号码+手机号)' }
    ],
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请选择结算单校验（微信）',
          trigger: 'change'
        }
      ]
    },
    config: {
      multiple: true,
      style: 'width: 350px'
    }
  },
  // 两单一物
  {
    sessionTitle: '两单一物',
    cmp: 'radio',
    label: '两单一物生成规则',
    prop: 'isDeliverable',
    options: [
      { key: 0, label: '系统设置' },
      { key: 1, label: '自定义' }
    ],
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请选择两单一物生成规则',
          trigger: 'change'
        }
      ]
    }
  },
  {
    cmp: 'slot',
    name: 'isDeliverableAmountLimit',
    show: '!!form.isDeliverable'
  },
  {
    cmp: 'number',
    label: '抽成占比',
    prop: 'deliverableCountLimit',
    afterSuffix: '%',
    config: {
      controls: false,
      min: 0,
      precision: 1,
      max: 100
    },
    itemConfig: {
      rules: [
        {
          required: true,
          message: '请填写抽成占比',
          trigger: ['blur', 'change']
        }
      ]
    },
    show: '!!form.isDeliverable'
  },
  // 电核生成规则
  // {
  //   sessionTitle: '电核规则',
  //   cmp: 'radio',
  //   label: '电核数据生成规则',
  //   prop: 'isEle',
  //   options: [
  //     { key: 0, label: '系统设置' },
  //     { key: 1, label: '自定义' }
  //   ],
  //   itemConfig: {
  //     rules: [{ required: true, message: '请选择两单一物生成规则', trigger: 'change' }]
  //   }
  // },
  // {
  //   cmp: 'slot',
  //   name: 'isELeAmountLimit',
  //   show: '!!form.isEle'
  // },
  // {
  //   cmp: 'number',
  //   label: '抽成占比',
  //   prop: 'eleCountLimit',
  //   afterSuffix: '%',
  //   config: {
  //     controls: false,
  //     min: 0,
  //     precision: 1,
  //     max: 100
  //   },
  //   itemConfig: {
  //     rules: [{ required: true, message: '请填写抽成占比', trigger: ['blur', 'change'] }]
  //   },
  //   show: '!!form.isEle'
  // },
  // 其他配置
  // 禁止发放时间段
  {
    sessionTitle: '其他配置',
    cmp: 'radio',
    label: '禁止发放时间段',
    prop: 'isProhibitGrant',
    options: [
      { key: 0, label: '系统设置' },
      { key: 1, label: '自定义' }
    ]
  },
  {
    cmp: 'slot',
    name: 'isTimeLimt',
    show: '!!form.isProhibitGrant'
  },
  {
    cmp: 'radio',
    label: '结算单提交&审核',
    prop: 'isAudit',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '自动提交' },
      { key: 2, label: '自动审核' }
    ]
  },
  {
    cmp: 'slot',
    name: 'limitAge',
    label: '发放年龄限制'
  },
  {
    cmp: 'radio',
    label: '自动发送创客签约短信',
    prop: 'isAutoSend',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '开启' }
    ]
  },
  {
    cmp: 'slot',
    name: 'isRiskNotification',
    label: '风控通知短信'
  },
  // {
  //   cmp: "radio",
  //   label: "风控通知短信",
  //   prop: "isRiskNotification",
  //   options: [
  //     { key: 0, label: "关闭" },
  //     { key: 1, label: "开启" },
  //   ],
  // },
  {
    cmp: 'slot',
    name: 'isTransferRemark'
  },
  {
    cmp: 'slot',
    name: 'isPunchRemark'
  },
  {
    cmp: 'radio',
    label: '自动发放',
    prop: 'autoGrant',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '开启' }
    ]
  },
  // {
  //   cmp: 'radio',
  //   label: '批量下载回单',
  //   prop: 'isBatchDownReceipt',
  //   options: [
  //     { key: 0, label: '关闭' },
  //     { key: 1, label: '开启' }
  //   ]
  // },
  {
    cmp: 'slot',
    name: 'isReturnNotify',
    label: '退汇短信通知'
  },
  // {
  //   cmp: "radio",
  //   label: "退汇短信通知",
  //   prop: "isReturnNotify",
  //   options: [
  //     { key: 0, label: "关闭" },
  //     { key: 1, label: "开启" },
  //   ],
  // },
  {
    cmp: 'input',
    label: '提醒手机号',
    prop: 'returnNotifyMobiles1',
    show: 'form.isReturnNotify == 1',
    col: 11,
    config: {
      style: 'width: 350px'
    },
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'input',
    label: '选填手机号',
    prop: 'returnNotifyMobiles2',
    col: 11,
    show: 'form.isReturnNotify == 1',
    config: {
      style: 'width: 350px'
    },
    itemConfig: {
      rules: [
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'radio',
    label: '支付短信',
    prop: 'isPayNotify',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '开启' }
    ]
  },
  {
    cmp: 'radio',
    label: '是否自制回单',
    prop: 'isHomemadeReceipt',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '全自制' },
      { key: 2, label: '当天自制' }
    ]
  }
]
const formNewLabels = [
  {
    cmp: 'slot',
    name: 'merchantSuffix'
  },
  {
    cmp: 'select',
    label: '选择商户',
    prop: 'merchantId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '输入商户名称搜索', trigger: 'change' }]
    },
    config: {
      filterable: true,
      style: 'width: 350px'
    }
  },
  {
    cmp: 'select',
    label: '选择税源地',
    prop: 'supplierId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '输入税源地名称搜索', trigger: 'change' }]
    },
    config: {
      filterable: true,
      remote: true,
      style: 'width: 350px'
    }
  },
  {
    cmp: 'txt',
    label: '税源地类型',
    prop: '_supplierTypeName',
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'change' }]
    }
  },
  ...channelForm('bank'),
  ...channelForm('alipay'),
  ...channelForm('wechat'),
  ...channelForm('alipayIsv'),
  ...channelForm('provider')
]
// 初始form参数
const initForm = {
  isPiecewise: 0,
  contractMode: 20,
  contractScheme: 10,
  isSigned: 1,
  isThirdInvoice: 0,
  isUploadBillPic: 0,
  minAmount: 1,
  settlementMakeInvoice: 0,
  isControl: 0,
  isDeliverable: 0,
  isAudit: 0,
  isAutoSend: 0,
  isRiskNotification: 0,
  autoGrant: 0,
  isBatchDownReceipt: 1,
  isProhibitGrant: 0,
  limitAgeType: 0,
  isTransferRemark: 0,
  isPunchRemark: 0,
  isReturnNotify: 0,
  isPayNotify: 1,
  isHomemadeReceipt: 0,
  verifyBank: ['0'],
  verifyWechat: ['0'],
  verifyAlipay: ['0'],
  verifyPeople: ['0']
}
// 测试用
const test = {
  // isThirdInvoice: 1,
  // hmCode: 'lx1236cdb24b0692feb5',
  // hmSecretKey: 'bb3877e7c38e4c914644519891aef2b4',
  // hmPublicKey: 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALCEHuvT8WOgDOGdC1DlRLlOVYDrBYuPFEy6L+XRprMkAFOLwBygDbbWzzZUfiMrRL1NtIxa8AbNHvTLOnxDt0sCAwEAAQ==',
  // mainstayId: '9'
}
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      add: 0,
      formConfig: {
        labelWidth: '180px'
      },
      formLabels: formLabels,
      formNewLabels,
      formNewLabels,
      detail: {},
      // 暂存变量:merchantName,memberCode,peopleTemplateCode,peopleTemplateName
      cacheForm: {},
      // 阶梯费率
      merchantTariffingList: [],
      // 删除的阶梯费率ids
      deledTariffingIds: [],
      // 备选电签模板列表，0活体电签，1快捷签约
      templates: [[], []],
      // 签约方类型
      stampOptions: [
        { label: '商户', key: 1 },
        { label: '创客', key: 2 },
        { label: '税源地', key: 3 },
        { label: '其他', key: 99 }
      ],
      //短信模板列表
      smsOptions: [],
      smsContent: '',
      smsReturnContent: '',
      // 签约方主体列表
      signatoryOptions: [],
      // 盖章关键字
      merchantKeywordList: [],
      // 备选基础税目
      baseTaxes: [],
      // 备选hm税目
      hmTaxes: [],
      // 已选税目-树状结构
      merchantTaxList: [],
      // 可开票税目校验信息
      taxError: '',
      // 可选税目popover状态
      showTaxPopover: false,
      // 可选所有税目树状结构
      taxItemsLevelList: [],
      // 拉平后的所有税目列表
      taxItemsFlat: [],
      // 可选hm税目树状结构
      taxHmItemsLevelList: [],
      // 拉平后的hm税目列表
      taxHmItemsFlat: [],
      // 已选择数目id数组
      checkTaxItemsId: [],
      // 新增channels
      newForm: [],
      merchantId: ''
    }
  },
  watch: {
    merchantTaxList(val) {
      if (val && val.length) this.taxError = ''
      else this.taxError = '请选择可开票税目'
    }
  },
  mounted() {
    // 处理fromLabels
    // this.formNewLabels.find((item) => item.prop == 'merchantId').config.remoteMethod = (val) => {
    //   this.getMerchantList(val)
    // }
    this.formNewLabels.find((item) => item.prop == 'supplierId').config.remoteMethod = (val) => {
      this.getSupplierList(val)
    }
  },
  methods: {
    formatStatus,
    clear() {
      this.detail = {}
      this.merchantTariffingList = []
      this.deledTariffingIds = []
      this.templates = [[], []]
      this.merchantKeywordList = []
      this.baseTaxes = []
      this.hmTaxes = []
      this.showTaxPopover = false
      this.merchantTaxList = []
      this.taxItemsLevelList = []
      this.taxItemsFlat = []
      this.taxHmItemsLevelList = []
      this.taxHmItemsFlat = []
      this.checkTaxItemsId = []
      this.newForm = []
    },
    async open(obj) {
      this.clear()
      // await this.getTaxItemsLevelList();
      await this.getTemplateList()
      await this.getBusinessMainCodeAndNameList()
      await this.getSmsParameterAllList()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.add = 0
        this.getDetail()
      } else {
        // 添加
        this.id = null
        this.add = 1
        // 获取商户列表
        this.getMerchantList()
        this.getSupplierList()
        this.show = true
        // 获取电签模板
        this.$nextTick(() => {
          this.$refs.vFormNew.fillData({
            bank_isRemitAccount: false,
            alipay_isRemitAccount: false,
            provider_isRemitAccount: false
          })
          this.$refs.vForm.fillData(Object.assign({}, initForm, test))
          // 获取电签模板
          this.changeTemplateOptions(initForm.contractMode)
        })
      }
    },
    // 添加部分的表单输入变更
    onInputChangeNew(val, key) {
      // 切换商户
      if (key == 'merchantId') {
        // 拉取merchantName, memberCode到cacheForm
        const { merchantName, memberCode } = this.formNewLabels
          .find((item) => item.prop == 'merchantId')
          .options.find((item) => item.merchantId == val)
        Object.assign(this.cacheForm, { merchantName, memberCode })
        // 处理特殊问题，如hm可选税目的变更
        return
      }
      // 切换税源地
      if (key == 'supplierId') {
        // 拉取supplierType到form
        const { supplierType } = this.formNewLabels.find((item) => item.prop == 'supplierId').options.find((item) => item.supplierId == val)
        this.$refs.vFormNew.upData(
          {
            _supplierType: supplierType,
            _supplierTypeName: formatStatus(signType, supplierType)
          },
          true
        )
        if (supplierType == 1) {
          this.formNewLabels.find((item) => item.prop == 'alipay_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          this.formNewLabels.find((item) => item.prop == 'alipayIsv_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          this.formNewLabels.find((item) => item.prop == 'bank_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          this.formNewLabels.find((item) => item.prop == 'wechat_openMode').options = kvOpenMode.filter((item) => item.key == 1)
        } else {
          this.formNewLabels.find((item) => item.prop == 'provider_openMode').options = kvOpenMode.filter((item) => item.key == 1 || item.key == 3)
          this.formNewLabels.find((item) => item.prop == 'bank_openMode').options = kvOpenMode.filter((item) => item.key == 1 || item.key == 3)
          this.formNewLabels.find((item) => item.prop == 'alipay_openMode').options = kvOpenMode
        }
        // 清空通道及账户
        this.$refs.vFormNew.upData(
          {
            alipay_channelId: '000',
            alipay_supplierAccountId: undefined,
            alipayIsv_channelId: '000',
            alipayIsv_supplierAccountId: undefined,
            bank_channelId: '000',
            bank_supplierAccountId: undefined,
            wechat_channelId: '000',
            wechat_supplierAccountId: undefined,
            provider_channelId: '000',
            provider_supplierAccountId: undefined
          },
          true
        )
        // 切换备选通道
        this.getChannelList(val, supplierType)
        return
      }
      // 切换通道
      if (key == 'alipay_channelId') {
        this.$refs.vFormNew.upData({ alipay_supplierAccountId: undefined }, true)
        return
      }
      if (key == 'alipayIsv_channelId') {
        this.$refs.vFormNew.upData({ alipayIsv_supplierAccountId: undefined }, true)
        return
      }
      if (key == 'bank_channelId') {
        this.$refs.vFormNew.upData({ bank_supplierAccountId: undefined }, true)
        return
      }
      if (key == 'provider_channelId') {
        this.$refs.vFormNew.upData({ provider_supplierAccountId: undefined }, true)
        return
      }
      if (key == 'wechat_channelId') {
        this.$refs.vFormNew.upData({ wechat_supplierAccountId: undefined }, true)
        return
      }
      // 切换开户方式
      if (key == 'alipay_openMode') {
        this.formNewLabels.find((item) => item.prop == 'alipay_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vFormNew.upData({ alipay_generationMode: 1 }, true)
          this.formNewLabels.find((item) => item.prop == 'alipay_generationMode').disabled = true
        } else {
          this.$refs.vFormNew.upData({ alipay_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'alipayIsv_openMode') {
        this.formNewLabels.find((item) => item.prop == 'alipayIsv_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vFormNew.upData({ alipayIsv_generationMode: 1 }, true)
          this.formNewLabels.find((item) => item.prop == 'alipayIsv_generationMode').disabled = true
        } else {
          this.$refs.vFormNew.upData({ alipayIsv_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'bank_openMode') {
        this.formNewLabels.find((item) => item.prop == 'bank_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vFormNew.upData({ bank_generationMode: 1 }, true)
          this.formNewLabels.find((item) => item.prop == 'bank_generationMode').disabled = true
        } else {
          this.$refs.vFormNew.upData({ bank_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'provider_openMode') {
        this.formNewLabels.find((item) => item.prop == 'provider_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vFormNew.upData({ provider_generationMode: 1 }, true)
          this.formNewLabels.find((item) => item.prop == 'provider_generationMode').disabled = true
        } else {
          this.$refs.vFormNew.upData({ provider_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'wechat_openMode') {
        this.formNewLabels.find((item) => item.prop == 'wechat_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vFormNew.upData({ wechat_generationMode: 1 }, true)
          this.formNewLabels.find((item) => item.prop == 'wechat_generationMode').disabled = true
        } else {
          this.$refs.vFormNew.upData({ wechat_generationMode: 2 }, true)
        }
        return
      }
    },
    // 表单输入变更
    async onInputChange(val, key) {
      // 切换电签类型
      if (key == 'contractMode') {
        const updata = { peopleTemplateId: undefined }
        if (val == 10) updata.contractScheme = 10
        // 清空peopleTemplateId
        this.$refs.vForm.upData(updata, true)
        // cacheForm清空peopleTemplateCode, peopleTemplateName
        Object.assign(this.cacheForm, {
          peopleTemplateCode: undefined,
          peopleTemplateName: undefined
        })
        // 切换电签模板列表
        this.changeTemplateOptions(val)
        // 清空盖章关键字
        this.merchantKeywordList = []
        return
      }
      // 切换电签模板
      if (key == 'peopleTemplateId') {
        // 拉取peopleTemplateCode, peopleTemplateName到cacheForm
        const { contractTemplateCode: peopleTemplateCode, contractTemplateName: peopleTemplateName } = this.templates[
          this.$refs.vForm.getFormItem('contractMode') == 20 ? 0 : 1
        ].list.find((item) => item.contractTemplateId == val)
        Object.assign(this.cacheForm, {
          peopleTemplateCode,
          peopleTemplateName
        })
        // 获取盖章关键字
        this.$api.GetContractTemplateKeywordList({ templateId: val }).then((res) => {
          this.merchantKeywordList = res.list.map((item) => {
            return {
              keyword: item.keyword,
              signatoryType: item.signatoryType,
              orderNo: item.orderNo,
              signatoryId: '',
              signatoryName: ''
            }
          })
        })
        return
      }
      // 切换三方开票服务
      if (key == 'isThirdInvoice') {
        // 清空可开票税目
        this.merchantTaxList = []
        this.checkTaxItemsId = []
        this.showTaxPopover = false
        return
      }
      // 切换校验信息
      if ((key == 'verifyPeople' || key == 'verifyAlipay' || key == 'verifyBank' || key == 'verifyWechat') && val.length) {
        // 判断最后一位是不是0，是0则只保存0，不是0则删除前面的0
        if (val[val.length - 1] == '0') {
          val.splice(0, val.length - 1)
        } else {
          const _idx = val.findIndex((n) => n == '0')
          if (_idx >= 0) val.splice(_idx, 1)
        }
        return
      }
      // if (key == 'isProhibitGrant') {
      //   let div = document.querySelector('.dialog-full-hasfoot')
      //   console.log(div.scrollHeight)
      //   div.scrollTop = div.scrollHeight
      //   console.log(div.scrollTop)
      // }
    },
    // 切换电签模板列表(包含结构处理)
    changeTemplateOptions(val) {
      if (val == 20) {
        let template = this.templates.find((e) => e.value == val)
        this.formLabels.find((item) => item.prop == 'peopleTemplateId').options = (template.list || []).map((item) => {
          return Object.assign(
            {
              label: item.contractTemplateName + '【' + item.contractTemplateCode + '】',
              key: item.contractTemplateId
            },
            item
          )
        })
      } else if (val == 30 || val == 10) {
        let template = this.templates.find((e) => e.value == 30)
        this.formLabels.find((item) => item.prop == 'peopleTemplateId').options = (template.list || []).map((item) => {
          return Object.assign(
            {
              label: item.contractTemplateName + '【' + item.contractTemplateCode + '】',
              key: item.contractTemplateId
            },
            item
          )
        })
      }
    },
    // 获取详情
    async getDetail() {
      const res = await this.$api.GetMerchantConfigInfo({
        merchantConfigId: this.id
      })
      // 需要的数据
      const {
        merchantId,
        supplierId,
        channelId,
        merchantName,
        memberCode,
        supplierName,
        supplierCode,
        channelName,
        channelCode,
        isPiecewise,
        serviceTariffing,
        channelTariffing,
        costServiceTariffing,
        contractMode,
        contractScheme,
        peopleTemplateCode,
        peopleTemplateId,
        peopleTemplateName,
        signRate,
        isSigned,
        isThirdInvoice,
        hmCode,
        hmSecretKey,
        hmPublicKey,
        mainstayId,
        isUploadBillPic,
        minAmount,
        isControl,
        sigleLimit,
        monthLimit,
        verifyPeople,
        verifyAlipay,
        verifyBank,
        verifyWechat,
        isDeliverable,
        isDeliverableAmountLimit,
        deliverableMinAmount,
        deliverableMaxAmount,
        deliverableCountLimit,
        // rechargeMakeInvoice,
        settlementMakeInvoice,
        isAudit,
        isAutoSend,
        isRiskNotification,
        riskSmsTemplate,
        returnSmsTemplate,
        autoGrant,
        isBatchDownReceipt,
        isProhibitGrant,
        limitAgeType,
        limitAgeMin,
        limitAgeMax,
        isTransferRemark,
        isPunchRemark,
        punchRemark,
        transferRemark,
        isProhibitGrantTimeLimit,
        prohibitGrantTimeStart,
        prohibitGrantTimeEnd,
        isReturnNotify,
        returnNotifyMobiles,
        isPayNotify,
        isHomemadeReceipt
      } = res
      // 处理详情数据
      const form = {
        merchantId,
        supplierId,
        channelId,
        _merchant: merchantName + '【' + memberCode + '】',
        _supplier: supplierName + '【' + supplierCode + '】',
        _channel: channelName + '【' + channelCode + '】',
        // 费率设置
        isPiecewise,
        serviceTariffing,
        channelTariffing,
        costServiceTariffing,
        // 电签设置
        contractMode,
        contractScheme,
        peopleTemplateId,
        signRate,
        isSigned,
        // 税票设置
        isThirdInvoice,
        hmCode,
        hmSecretKey,
        hmPublicKey,
        mainstayId,
        isUploadBillPic,
        // rechargeMakeInvoice,
        settlementMakeInvoice,
        // 额度管控
        isControl,
        sigleLimit,
        monthLimit,
        minAmount,
        // 要素验证
        verifyPeople: verifyPeople.split('|'),
        verifyAlipay: verifyAlipay.split('|'),
        verifyBank: verifyBank.split('|'),
        verifyWechat: verifyWechat.split('|'),
        // 两单一物
        isDeliverable,
        isDeliverableAmountLimit,
        deliverableMinAmount,
        deliverableMaxAmount,
        deliverableCountLimit,
        // 其他配置
        isAudit,
        isAutoSend,
        isRiskNotification,
        riskSmsTemplate,
        returnSmsTemplate,
        autoGrant,
        isBatchDownReceipt,
        isProhibitGrant,
        limitAgeType,
        limitAgeMin,
        limitAgeMax,
        isTransferRemark,
        transferRemark,
        isPunchRemark,
        punchRemark,
        transferRemark,
        isProhibitGrantTimeLimit,
        prohibitGrantTimeStart,
        prohibitGrantTimeEnd,
        isReturnNotify,
        // 预留手机号
        returnNotifyMobiles1: returnNotifyMobiles[0] || '',
        returnNotifyMobiles2: returnNotifyMobiles[1] || '',
        isPayNotify,
        isHomemadeReceipt
      }
      this.cacheForm = {
        merchantName,
        memberCode,
        peopleTemplateCode,
        peopleTemplateName
      }
      this.merchantId = merchantId
      // 获取税源地税目
      await this.getTaxItemsLevelList(supplierId)
      // 获取电签模板
      this.changeTemplateOptions(contractMode)
      // 获取税目信息
      this.getMerchantTaxList(isThirdInvoice, merchantId, hmCode, hmSecretKey, hmPublicKey, mainstayId)
      this.getReturnSmsContent(returnSmsTemplate)
      this.getSmsContent(riskSmsTemplate)
      // 获取阶梯费率
      this.getMerchantTariffingList(merchantId)
      // 获取关键字
      this.getMerchantKeywordList(merchantId)
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(Object.assign({ _isEdit: true }, initForm, form))
      })
    },
    // 获取电签模板列表
    async getTemplateList() {
      const result = await this.$api.GetTemplateListBySignType()
      this.templates = result.list.sort((a, b) => a.value - b.value)
    },
    // 获取商户列表
    async getMerchantList(MerchantName) {
      let taskRes = await this.$api.GetMerchantInfoCodeAndName()
      // const res = await this.$api.GetMerchantListBySearch({ MerchantName })
      // 处理数据结构并赋值
      this.formNewLabels.find((item) => item.prop == 'merchantId').options = (taskRes.list || []).map((item) => {
        return Object.assign(
          {
            label: item.merchantName + '【' + item.memberCode + '】',
            key: item.merchantId
          },
          item
        )
      })
    },
    // 获取税源地列表
    async getSupplierList(SupplierName) {
      const res = await this.$api.GetSupplierListBySearchNoEnable({ SupplierName })
      // 处理数据结构并赋值
      this.formNewLabels.find((item) => item.prop == 'supplierId').options = (res.list || []).map((item) => {
        return Object.assign(
          {
            label: item.supplierName + '【' + item.supplierCode + '】',
            key: item.supplierId
          },
          item
        )
      })
    },
    // 获取支付通道
    async getChannelList(supplierId, supplierType) {
      // 获取其通道信息,GrantType 发放类型,1:支付宝,2:银行卡,3:微信,4:供应商,5:支付宝ISV
      const res = await this.$api.GetAddMerchantBySupplierChannelInfo({
        merchantId: '',
        supplierId
      })
      let bankOptions = []
      let alipayOptions = []
      bankOptions.push(...res.bankChannelList, ...res.providerChannelList)
      alipayOptions.push(...res.alipayChannelList, ...res.providerChannelList)
      // 处理数据结构并赋值
      // 支付宝通道
      this.formNewLabels.find((item) => item.prop == 'alipay_channelId').options = (
        supplierType == 1 ? res.alipayChannelList : alipayOptions || []
      ).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })
      this.formNewLabels
        .find((item) => item.prop == 'alipay_channelId')
        .options.unshift({
          label: '不开启',
          key: '000'
        })
      // 支付宝账户
      this.formNewLabels.find((item) => item.prop == 'alipay_supplierAccountId').options = (res.rechargeBankChannelList || []).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })

      // 支付宝ISV通道
      this.formNewLabels.find((item) => item.prop == 'alipayIsv_channelId').options = (
        supplierType == 1 ? res.alipayChannelList : alipayOptions || []
      ).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })
      this.formNewLabels
        .find((item) => item.prop == 'alipayIsv_channelId')
        .options.unshift({
          label: '不开启',
          key: '000'
        })
      // 支付宝ISV账户
      this.formNewLabels.find((item) => item.prop == 'alipayIsv_supplierAccountId').options = (res.rechargeBankChannelList || []).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })

      // 微信通道
      this.formNewLabels.find((item) => item.prop == 'wechat_channelId').options = (supplierType == 1 ? res.wechatChannelList : []).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })
      this.formNewLabels
        .find((item) => item.prop == 'wechat_channelId')
        .options.unshift({
          label: '不开启',
          key: '000'
        })
      // 微信账户
      this.formNewLabels.find((item) => item.prop == 'wechat_supplierAccountId').options = (res.rechargeBankChannelList || []).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })

      // 银行卡通道
      this.formNewLabels.find((item) => item.prop == 'bank_channelId').options = (supplierType == 1 ? res.bankChannelList : bankOptions || []).map(
        (item) => {
          return Object.assign(
            {
              label: item.channelName,
              key: item.channelId
            },
            item
          )
        }
      )
      this.formNewLabels
        .find((item) => item.prop == 'bank_channelId')
        .options.unshift({
          label: '不开启',
          key: '000'
        })
      // 银行卡账户
      this.formNewLabels.find((item) => item.prop == 'bank_supplierAccountId').options = (res.rechargeBankChannelList || []).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })
      // 供应商通道
      this.formNewLabels.find((item) => item.prop == 'provider_channelId').options = (res.providerChannelList || []).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })
      this.formNewLabels
        .find((item) => item.prop == 'provider_channelId')
        .options.unshift({
          label: '不开启',
          key: '000'
        })
      // 供应商账户
      this.formNewLabels.find((item) => item.prop == 'provider_supplierAccountId').options = (res.rechargeBankChannelList || []).map((item) => {
        return Object.assign(
          {
            label: item.channelName,
            key: item.channelId
          },
          item
        )
      })
    },
    // 获取税目信息
    async getMerchantTaxList(isThirdInvoice, merchantId, hmCode, hmSecretKey, hmPublicKey, mainstayId) {
      const res = await this.$api.GetMerchantConfigTaxList({
        configId: this.id,
        merchantId
      })
      // 节点转换为tags显示用
      this.checkTaxItemsId = res.list
      const parentsId = new Set()
      // 拉平数据
      let listFlat = this.taxItemsFlat
      // 备选数据
      let list = this.taxItemsLevelList
      if (isThirdInvoice) {
        // 使用第三方税目
        await this.getHmTaxItemsLevelList(this.merchantId, hmCode, hmSecretKey, hmPublicKey, mainstayId)
        // 修改数据为Hm
        listFlat = this.taxHmItemsFlat
        list = this.taxHmItemsLevelList
      }
      // 处理逻辑
      const merchantTaxItemList = res.list.map((item) => {
        const findIt = listFlat.find((it) => {
          return it.value == item
        })
        if (findIt) {
          parentsId.add(findIt.parent_value)
          return findIt
        }
      })
      // 父
      const merchantTaxList = list
        .filter((item) => {
          return parentsId.has(item.value)
        })
        .map((item) => {
          return Object.assign({}, item, { children: [] })
        })
      // 子塞入父
      merchantTaxItemList.map((item) => {
        if (!item || !item.parent_value) return
        const me = merchantTaxList.find((it) => {
          return it.value == item.parent_value
        })
        if (me) me.children.push(item)
      })
      this.merchantTaxList = merchantTaxList
    },
    // 获取阶梯税率信息
    async getMerchantTariffingList(merchantId) {
      const res = await this.$api.GetMerchantConfigTariffingList({
        configId: this.id,
        merchantId
      })
      this.merchantTariffingList = res.list
    },
    // 获取商户配置盖章关键字
    async getMerchantKeywordList(merchantId) {
      const res = await this.$api.GetMerchantConfigKeywordList({
        configId: this.id,
        merchantId
      })
      this.merchantKeywordList = res.list
      res.list.map((item) => {
        this.onSignatoryChange(item.signatoryId, item)
      })
    },
    // 获取当前阶梯前置发放金额
    issueAmountBefore(idx) {
      if (idx == 0) return 0
      return this.merchantTariffingList[idx - 1].issueAmount
    },
    // 添加阶梯税率信息
    addTariffing() {
      this.merchantTariffingList.push({
        serviceTariffing: 0,
        costServiceTariffing: 0
      })
    },
    // 删除阶梯税率信息
    deleteTariffing(item, idx) {
      if (item.id) this.deledTariffingIds.push(item.id)
      this.merchantTariffingList.splice(idx, 1)
    },
    // 校验阶梯税率列表
    validateFieldTariffing() {
      this.$refs.vForm.validateField()
    },

    // 获取签约方主体信息下拉列表
    async getBusinessMainCodeAndNameList(val) {
      const res = await this.$api.GetBusinessMainCodeAndNameList()
      this.signatoryOptions = res.list
    },
    // 获取短信模板列表
    async getSmsParameterAllList(val) {
      const riskRes = await this.$api.GetSmsTemplateListByDropDown({
        SmsCategory: '风控通知短信'
      })
      const res = await this.$api.GetSmsTemplateListByDropDown({
        SmsCategory: '退汇短信通知'
      })
      this.smsOptions = riskRes.list
      this.smsReturnOptions = res.list
    },
    // 签约方主体切换, 填充name，并判断授权
    async onSignatoryChange(val, item) {
      if (val) {
        const one = this.signatoryOptions.find((it) => it.mainId == val)
        if (one) {
          const res = await this.$api.GetBusinessMainAuthorizationState({
            MainId: val
          })
          this.$set(item, 'signatoryName', one.mainName)
          this.$set(
            item,
            'authorState',
            formatStatus(
              [
                { key: 0, label: '未授权' },
                { key: 1, label: '已授权' }
              ],
              res.authorState,
              ''
            )
          )
        } else {
          this.$set(item, 'signatoryId', '')
          this.$set(item, 'signatoryName', '')
          this.$set(item, 'authorState', '')
        }
      } else {
        this.$set(item, 'signatoryName', '')
        this.$set(item, 'authorState', '')
      }
    },

    // 获取全部税目列表
    async getTaxItemsLevelList(supplierId) {
      // const res = await this.$api.GetTaxItemsLevelList()
      const res = await this.$api.GetSupplierTaxList({ supplierId })
      this.taxItemsLevelList = res.list.map((item) => {
        if (item.children)
          item.children = item.children.map((it) => {
            it.parent_value = item.value
            return it
          })
        return item
      })
      // 拉平
      this.taxItemsFlat = res.list.flatMap((item) => [item, ...item.children])
    },
    // 获取河马税目列表
    async getHmTaxItemsLevelList(merchantId, hmCode, hmSecretKey, hmPublicKey, mainstayId) {
      // 判断时候有效
      await this.$api.QueryMerchantHmTaxCode({
        merchantId,
        hmCode,
        hmSecretKey,
        hmPublicKey,
        mainstayId
      })
      // 获取数据
      const res = await this.$api.GetTaxByHmcode({ hmCode, mainstayId })
      // 处理数据
      const list = []
      res.list.forEach((item) => {
        const one = list.find((it) => item.key == it.hmCode)
        if (one) {
          one.children.push({
            key: item.childrenId,
            taxCode: item.taxCode,
            title: item.childrenTaxName,
            value: item.childrenId,
            parent_value: item.hmCode
          })
        } else {
          list.push({
            key: item.hmCode,
            taxCode: item.hmCode,
            title: item.parentTaxName,
            value: item.hmCode,
            children: [
              {
                key: item.childrenId,
                taxCode: item.taxCode,
                title: item.childrenTaxName,
                value: item.childrenId,
                parent_value: item.hmCode
              }
            ]
          })
        }
      })
      this.taxHmItemsLevelList = list
      this.taxHmItemsFlat = list.flatMap((item) => [item, ...item.children])
    },
    // 打开添加数目popover
    async openTaxPopover() {
      // 判断是否第三方
      if (this.$refs.vForm.getFormItem('isThirdInvoice')) {
        // 开启，判断hm参数是否填写
        // const merchantId = this.$refs.vForm.getFormItem('merchantId')
        const hmCode = this.$refs.vForm.getFormItem('hmCode')
        const hmSecretKey = this.$refs.vForm.getFormItem('hmSecretKey')
        const hmPublicKey = this.$refs.vForm.getFormItem('hmPublicKey')
        const mainstayId = this.$refs.vForm.getFormItem('mainstayId')
        // if (!merchantId) {
        //   return this.$message.error('添加第三方税目前，请先选择商户')
        // }
        if (!hmCode) {
          return this.$message.error('添加第三方税目前，请先填写appKey')
        }
        if (!hmSecretKey) {
          return this.$message.error('添加第三方税目前，请先填写secretKey')
        }
        if (!hmPublicKey) {
          return this.$message.error('添加第三方税目前，请先填写publicKey')
        }
        if (!mainstayId) {
          return this.$message.error('添加第三方税目前，请先填写mainstayId')
        }
        this.showPopver = true
        await this.getHmTaxItemsLevelList(this.newForm.merchantId || this.merchantId, hmCode, hmSecretKey, hmPublicKey, mainstayId)
        this.showTaxPopover = true
        // res.list.map((item) => {
        //   if (item.children)
        //     item.children = item.children.map((it) => {
        //       it.parent_value = item.value
        //       return it
        //     })
        //   return item
        // })
      } else {
        this.showTaxPopover = true
      }
    },
    // 已选税目生成树
    getTaxTree() {
      const allList = this.$refs.taxAllTree.getCheckedNodes(false, true)
      // 父
      const merchantTaxList = allList
        .filter((item) => {
          return 'children' in item
        })
        .map((item) => {
          return Object.assign({}, item, { children: [] })
        })
      // 子塞入父
      allList.map((item) => {
        if (!item.parent_value) return
        const me = merchantTaxList.find((it) => {
          return it.value == item.parent_value
        })
        if (me) me.children.push(item)
      })
      this.merchantTaxList = merchantTaxList
      this.checkTaxItemsId = this.$refs.taxAllTree.getCheckedKeys(true)
      this.showTaxPopover = false
    },

    // 下一步
    async nextStep() {
      const form = await this.$refs.vFormNew.getForm()
      // 判断type2的通道是否互斥：银行卡&&支付宝、供应商
      if (form._supplierType == 2 && (form.bank_channelId != '000' || form.alipay_channelId != '000') && form.provider_channelId != '000')
        return this.$message.warning('开启银行卡或支付宝通道时，请关闭供应商通道')
      // 处理channels
      const channels = []
      const bankChannel = channelSubmit(form, 'bank')
      if (bankChannel) channels.push({ type: 1, ...bankChannel })
      const alipayChannel = channelSubmit(form, 'alipay')
      if (alipayChannel) channels.push({ type: 2, ...alipayChannel })
      const alipayIsvChannel = channelSubmit(form, 'alipayIsv')
      if (alipayIsvChannel) channels.push({ type: 5, ...alipayIsvChannel })
      const wechatChannel = channelSubmit(form, 'wechat')
      if (wechatChannel) channels.push({ type: 3, ...wechatChannel })
      const providerChannel = channelSubmit(form, 'provider')
      if (providerChannel) channels.push({ type: 4, ...providerChannel })
      this.newForm = {
        merchantId: form.merchantId,
        supplierId: form.supplierId,
        channels
      }
      // 带入第一步的信息
      const merchantInfo = this.formNewLabels.find((item) => item.prop == 'merchantId').options.find((item) => item.merchantId == form.merchantId)
      const supplierInfo = this.formNewLabels.find((item) => item.prop == 'supplierId').options.find((item) => item.supplierId == form.supplierId)
      this.$refs.vForm.upData({
        channelId: merchantInfo.channelId,
        _merchant: merchantInfo.merchantName + '【' + merchantInfo.memberCode + '】',
        _supplier: supplierInfo.supplierName + '【' + supplierInfo.supplierCode + '】',
        _channel: merchantInfo.channelName + '【' + merchantInfo.channelCode + '】'
      })
      this.add = 2
      await this.getTaxItemsLevelList(form.supplierId)
    },
    getSmsContent(e) {
      const one = this.smsOptions.find((it) => it.id == e)
      if (one) this.smsContent = one.smsContent || ''
      else this.smsContent = ''
    },
    getReturnSmsContent(e) {
      const one = this.smsReturnOptions.find((it) => it.id == e)
      if (one) this.smsReturnContent = one.smsContent || ''
      else this.smsReturnContent = ''
    },
    // 上一步
    async prevStep() {
      this.add = 1
    },
    // 提交
    async submit() {
      const form = await this.$refs.vForm.getForm()
      if (form.returnNotifyMobiles1) {
        form.returnNotifyMobiles = []
        form.returnNotifyMobiles[0] = form.returnNotifyMobiles1
      }
      if (form.returnNotifyMobiles2) form.returnNotifyMobiles[1] = form.returnNotifyMobiles2
      delete form.returnNotifyMobiles1
      delete form.returnNotifyMobiles2
      // 暂存变量
      const { merchantName, memberCode, peopleTemplateCode, peopleTemplateName } = this.cacheForm
      // 附加判断=====================================
      // 阶梯费率的判断
      if (form.isPiecewise == 1) {
        if (!this.merchantTariffingList.length) return this.$message.error(`请至少输入1项阶梯费率`)
        for (let idx in this.merchantTariffingList) {
          const index = parseInt(idx)
          const item = this.merchantTariffingList[index]
          // 判断空值
          if (isEmpty(item.issueAmount) || isEmpty(item.serviceTariffing) || isEmpty(item.costServiceTariffing)) {
            return this.$message.error(`请将第${index + 1}项阶梯费率输入完整`)
          }
          // 判断阶梯
          if (index != 0 && item.issueAmount <= this.merchantTariffingList[index - 1].issueAmount) {
            return this.$message.error(`第${index + 1}项阶梯费率的发放金额不能小于上一项发放金额`)
          }
        }
      }
      // 判断关键字
      for (let idx in this.merchantKeywordList) {
        const item = this.merchantKeywordList[idx]
        if (item.signatoryType != 1 && item.signatoryType != 2 && !item.signatoryId) return this.$message.error(`请填写盖章关键字【${item.keyword}】`)
        const one = this.merchantKeywordList.find(
          (it, ix) => ix != idx && it.signatoryType == item.signatoryType && it.signatoryId == item.signatoryId
        )
        if (one) return this.$message.error(`盖章关键字【${item.keyword}】与【${one.keyword}】，不能选择相同签约方`)
      }
      // 判断开票类型限制
      if (form.settlementMakeInvoice == 2 && (form.isThirdInvoice == 1 || form.isThirdInvoice == 2))
        return this.$message.error('切换到按金额开票，需关闭第三方开票服务')
      // 判断税目
      if (!this.merchantTaxList.length) return (this.hmTaxError = '请选择可开票税目')
      // 附加判断结束，处理特殊项=====================================
      // 处理阶梯费率
      const saveMerchantTariffing = {
        merchantTariffingList: this.merchantTariffingList.map((item) => {
          return Object.assign({ merchantId: form.merchantId, merchantName, memberCode }, item)
        }),
        ids: this.deledTariffingIds,
        merchantId: form.merchantId
      }
      // 处理税目
      const saveMerchantTax = {
        merchantId: form.merchantId,
        merchantTaxList: this.checkTaxItemsId.map((item) => ({ taxId: item }))
      }
      // 处理校验
      const verifyPeople = form.verifyPeople.join('|')
      const verifyAlipay = form.verifyAlipay.join('|')
      const verifyBank = form.verifyBank.join('|')
      const verifyWechat = form.verifyWechat.join('|')
      // 处理参数&暂存
      const extForm = {
        saveMerchantTariffing,
        saveMerchantTax,
        verifyBank,
        verifyWechat,
        verifyAlipay,
        verifyPeople,
        peopleTemplateCode,
        peopleTemplateName,
        keywordDtos: this.merchantKeywordList
      }
      // 处理特殊项结束，提交=====================================
      if (this.id) {
        // 保存
        const res = await this.$api.SaveMerchantConfigInfo(Object.assign({ id: this.id }, form, extForm))
      } else {
        // 新增
        const res = await this.$api.AddMerchantConfig(Object.assign({ ...this.newForm }, form, extForm))
      }
      this.$message.success('配置成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
