<template>
  <el-dialog :title="!id ? '添加' : '编辑'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <div v-if="show">
      <FormView ref="vForm" :labels="fromLabels"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { kvEmployeeJobState } from '@/configs/formatters'
import { regular } from '@/configs/validate'
const fromLabels = [
  {
    cmp: 'input',
    label: '工号',
    prop: 'employeeCode',
    config: { disabled: true, placeholder: '系统生成' }
  },
  {
    cmp: 'input',
    label: '商务姓名',
    prop: 'employeeName',
    itemConfig: {
      rules: [{ required: true, message: '请输入商务姓名', trigger: 'blur' }]
    },
    config: { maxlength: 20 }
  },
  {
    cmp: 'input',
    label: '联系方式',
    prop: 'employeePhone',
    itemConfig: {
      rules: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
    },
    itemConfig: {
      rules: [
        { required: true, message: '请输入联系方式', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { maxlength: 11 }
  },
  {
    cmp: 'select',
    label: '组织',
    prop: 'organizationName',
    itemConfig: {
      rules: [{ required: true, message: '请选择组织', trigger: 'change' }]
    },
    options: []
  },
  {
    cmp: 'radio',
    label: '状态',
    prop: 'jobState',
    options: kvEmployeeJobState,
    itemConfig: {
      rules: [{ required: true, message: '请选择状态', trigger: 'change' }]
    }
  }
]
export default {
  data() {
    return {
      show: false,
      id: null,
      fromLabels,
      btnloadSubmit: false
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
    },
    open(obj) {
      this.clear()
      this.show = true
      this.getSource()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.$nextTick(() => {
          this.$refs.vForm.fillData({ ...obj })
        })
      } else {
        // 添加
        this.$nextTick(() => {
          this.$refs.vForm.fillData({ jobState: 0 })
        })
      }
    },
    // 获取组织数据源
    async getSource() {
      const res = await this.$api.GetSystemDataDicList({ DataCategory: 'organizationName' })
      const options = (res.list || []).map((it) => {
        return {
          key: it.dataValue,
          label: it.dataName
        }
      })
      this.fromLabels.find((item) => item.prop == 'organizationName').options = options
    },
    async submit() {
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      if (this.id) {
        // 编辑
        const res = await this.$api.EditEmployeeInfo(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddEmployeeInfo(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
